import styled, { css } from 'styled-components';

import { getTypographyStyle, centeredGridColumns, media } from '@utils/styled';

export const CMSBlockContainer = styled.div`
  grid-column: ${centeredGridColumns(12)};
  ${getTypographyStyle('paragraph1')}
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};

  ${media.from640up`
    grid-column: ${centeredGridColumns(10)};
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(8)};
  `}

  ${({ shouldUseFixedTypography = true }) =>
    shouldUseFixedTypography &&
    css`
      h1,
      .h1 {
        ${getTypographyStyle('h1')}
      }

      h2,
      .h2 {
        ${getTypographyStyle('h2')}
      }

      h3,
      .h3 {
        ${getTypographyStyle('h3')}
      }

      h4,
      .h4 {
        ${getTypographyStyle('h4')}
      }

      h5,
      .h5 {
        ${getTypographyStyle('h5')}
      }

      h6,
      .h6 {
        ${getTypographyStyle('h6')}
      }

      p {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
      }

      h1:not([class]),
      h2:not([class]),
      h3:not([class]),
      h4:not([class]),
      h5:not([class]),
      h6:not([class]) {
        margin-bottom: 0.7em;
      }
    `}

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  ul {
    padding-left: 2rem;
  }

  ol {
    padding-left: 3.3rem;
  }

  p,
  ul,
  ol {
    position: relative;
    margin-top: 0;
    margin-bottom: 1.3rem;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
`;
