import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';

import {
  Container,
  Group,
  GroupDescription,
  GroupsContainer,
  GroupTitle,
  Item,
  ItemPrice,
  ItemTitle,
  Title,
} from './OfferList.styled';

interface OfferListProps {
  value: {
    offerGroups: {
      description?: string;
      items: {
        price?: string;
        title: string;
      }[];
      title: string;
    }[];
    title: string;
  };
}

const OfferList = ({ value }: OfferListProps) => {
  const { title, offerGroups } = value;

  return (
    <Container>
      <Title>{title}</Title>
      <GroupsContainer>
        {offerGroups.map(group => (
          <Group key={group.title}>
            <GroupTitle>{group.title}</GroupTitle>
            {group.items.map(item => (
              <Item key={item.title}>
                <ItemTitle>{item.title}</ItemTitle>
                {item.price && <ItemPrice>{item.price}</ItemPrice>}
              </Item>
            ))}
            {group.description && (
              <GroupDescription>
                <HTMLRenderer html={group.description} />
              </GroupDescription>
            )}
          </Group>
        ))}
      </GroupsContainer>
    </Container>
  );
};

export default OfferList;
