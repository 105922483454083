import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer/HTMLRenderer';

import { Container } from './Paragraph.styled';

interface ParagraphProps {
  value: string;
}

const Paragraph = ({ value }: ParagraphProps) => (
  <Container>
    <HTMLRenderer html={value} />
  </Container>
);

export default Paragraph;
