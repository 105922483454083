import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import TeaserBlockBase, { Description, Label, Title } from '@common/components/TeaserBlockBase';
import PriceText from '@common/components/PriceText';
import { GOODIES_DETAILS } from '@common/routes';

const GiftTeaser = ({ value, index, firstAsHero, reverseLayout }) => {
  const { locale } = useRouter();
  const { gift, linkText } = value;
  const { id: pageId = 23, title, images, lead, slug: pageSlug = 'tbd', label } = gift;

  const { t } = useTranslation();
  const shouldBeHero = index === 0 && firstAsHero;

  const href = {
    pathname: GOODIES_DETAILS,
    query: {
      id: pageId,
      slug: pageSlug,
    },
  };
  const as = getRouteUrl(locale, GOODIES_DETAILS, {
    id: pageId,
    slug: pageSlug,
  });

  return (
    <TeaserBlockBase
      image={images?.[0]}
      data-block-name="GiftTeaser"
      index={index}
      reverseLayout={reverseLayout}
      asHero={shouldBeHero}
      link={{
        href,
        as,
      }}
    >
      <Label>{label || t('cmsBlock.giftTeaser.label', 'VIP-Geschenk')}</Label>
      <Title>
        <PriceText>{title}</PriceText>
      </Title>
      {lead && (
        <Description clamp>
          <PriceText>{lead}</PriceText>
        </Description>
      )}

      <Link legacyBehavior
href={href}
passHref
as={as}>
        <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
          {linkText || t('cmsBlock.giftTeaser.moreLinkText', 'Mehr erfahren')}
        </ButtonLink>
      </Link>
    </TeaserBlockBase>
  );
};

GiftTeaser.propTypes = {
  firstAsHero: PropTypes.bool,
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    gift: PropTypes.shape({
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.shape({})),
      label: PropTypes.string,
      lead: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string.isRequired,
    }).isRequired,
    linkText: PropTypes.string,
  }).isRequired,
  reverseLayout: PropTypes.bool.isRequired,
};

GiftTeaser.defaultProps = {
  firstAsHero: false,
};

export default GiftTeaser;
