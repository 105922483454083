import styled from 'styled-components';

import Typography from '@common/components/Typography';

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 3)};
`;

export const MobilePlansWrapper = styled.section`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'section', 1)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 1)};
`;
