import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

import {
  IconFactGridBlockWrapper,
  FactWrapper,
  FactTitle,
  FactSubTitle,
  Container,
} from './IconFactGridBlock.styled';

const IconFactGridBlock = ({ value }) => (
  <Container>
    <IconFactGridBlockWrapper>
      {value?.items &&
        value?.items.map(({ fact, icon, subFact }) => (
          <FactWrapper key={fact}
data-testid="mobileIcon">
            <Image src={icon}
alt="icon"
width={80}
height={80} />
            <FactTitle>{fact}</FactTitle>
            <FactSubTitle>{subFact}</FactSubTitle>
          </FactWrapper>
        ))}
    </IconFactGridBlockWrapper>
  </Container>
);

IconFactGridBlock.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        fact: PropTypes.string,
        icon: PropTypes.string,
        subFact: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default IconFactGridBlock;
