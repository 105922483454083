import PropTypes from 'prop-types';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsFetching, selectProductColorVariations } from '@selectors/products';
import * as productsThunks from '@thunks/products';

import { PREFIXED_INTERNAL_LINK_DOMAINS } from '@utils/domains';
import { getLinkDataFromUrl } from '@utils/urls';

import LoadingIndicator from '@common/components/LoadingIndicator';
import { ProductsGrid, ProductWrapper } from '@common/components/ProductsGrid';
import Product from '@common/containers/Product/Product';
import Button, { BUTTON_VARIANTS } from '@common/components/Button';

import {
  Container,
  GridContainer,
  LoadingContainer,
  ButonWrapper,
} from './LookbookProductsGrid.styled';

const LookbookProductsGrid = ({ value }) => {
  const { items, buttonText, url } = value;
  const productIds = items.map(i => i.productColorId);
  const products = useSelector(selectProductColorVariations(productIds));
  const isFetchingProducts = useSelector(selectIsFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    if (products.length < productIds.length) {
      dispatch(productsThunks.fetchProductsByColorIds(productIds));
    }
  }, []);

  const linkURL = PREFIXED_INTERNAL_LINK_DOMAINS.some(domain => url?.includes(domain)) ? url : null;
  const linkData = linkURL && getLinkDataFromUrl(linkURL);

  const link = linkData ? (
    <Link legacyBehavior
href={linkData.urlObject}
as={linkData.as}>
      <a>
        <Button variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>{buttonText}</Button>
      </a>
    </Link>
  ) : (
    <a href={url}
target="_blank"
rel="noopener noreferrer">
      <Button variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>{buttonText}</Button>
    </a>
  );

  return (
    <Container>
      {isFetchingProducts && (
        <LoadingContainer>
          <LoadingIndicator isLarge />
        </LoadingContainer>
      )}
      {!isFetchingProducts && (
        <GridContainer>
          {/* eslint-disable react/no-array-index-key */}
          <ProductsGrid>
            {products.map((product, index) => (
              <ProductWrapper key={`${product.productColorId}_${index}`}>
                <Product {...product} />
              </ProductWrapper>
            ))}
          </ProductsGrid>
          {/* eslint-enable react/no-array-index-key */}
        </GridContainer>
      )}
      {buttonText && url && buttonText?.length > 0 && <ButonWrapper>{link}</ButonWrapper>}
    </Container>
  );
};

LookbookProductsGrid.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        productColorId: PropTypes.string.isRequired,
      })
    ),
    buttonText: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default LookbookProductsGrid;
