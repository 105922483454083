import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import LazyImg from '@common/components/LazyImg';
import Pill from '@common/components/Pill';

export const Container = styled.div`
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-left', 'widthBased', 0, true, 'safe-area-inset-left')};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-right', 'widthBased', 0, true, 'safe-area-inset-right')};

  ${media.from880up`
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 0)};
    box-shadow: none;
    margin-right: 0;
    margin-left: 0;
  `}

  ${media.from880down`
    ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 1, true)};
  `}

  ${media.from800down`
    margin-top: 0;
  `}
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  background-color: ${getThemeColor('bluegrey.95')};
  line-height: 0;

  ${media.from880up`
    border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  `}

  ${media.from1600up`
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  `}

  &::before {
    content: '';
    display: block;
    height: 70vh;
    ${({ theme }) => theme.getFluidSpacing('max-height', 'heroImage', 1)};
    ${({ theme }) => theme.getFluidSpacing('min-height', 'heroImage', 0)};
  }
`;

export const LazyImage = styled(LazyImg)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const Content = styled.div`
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 3)};
  ${({ theme }) =>
    theme.getFluidSpacing('padding-bottom', 'widthBas' + 'ed', 0, false, 'safe-area-inset-left')};
  ${({ theme }) =>
    theme.getFluidSpacing('padding-left', 'widthBased', 0, false, 'safe-area-inset-left')};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 0)};

  ${media.from880up`
    position: absolute;
    bottom: 0;
    left: 0;
    ${({ theme }) => theme.getFluidAttribute('width', 30, 46)};
    margin: 0 0 3rem 3rem;
    border: 0.2rem solid ${getThemeColor('bluegrey.80')};
    background-color: ${getThemeColor('grey.100')};
    border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
    ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 8)};
    ${({ theme }) => theme.getFluidSpacing('padding-right', 'equal', 1)};
    ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'equal', 8)};
    ${({ theme }) => theme.getFluidSpacing('padding-left', 'equal', 1)};
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h3',
})`
  color: ${getThemeColor('bluegrey.15')};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)};
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'body2',
})`
  margin-bottom: 0.5rem;
  color: ${getThemeColor('bluegrey.60')};
`;

export const PricePill = styled(Pill)`
  margin-bottom: 1.5rem;
  border-color: ${getThemeColor('bluegrey.70')};
  background-color: transparent;
  color: ${getThemeColor('bluegrey.15')};

  & > span {
    font-weight: 300;
    font-size: 14px;
  }

  ${media.from800up`
    margin-bottom: 1.5rem;
  `}
`;
