import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import MobilePlanBlock from '@common/components/CMSComponents/MobilePlanBlock';

import { MobilePlansWrapper, Title } from './MobilePlanListBlock.styled';

const MobilePlanListBlock = ({ value }) => {
  const { query } = useRouter();

  const mobilePlans = useMemo(
    () => value.mobilePlans.filter(({ slug }) => slug !== query.slug),
    [query.slug, value.mobilePlans]
  );

  return (
    <MobilePlansWrapper data-testid="mobilePlans">
      <Title>{value.heading}</Title>
      {mobilePlans.map((plan, mobilePlanIndex) => (
        <MobilePlanBlock
          value={{
            mobilePlan: plan,
          }}
          reverseLayout={mobilePlanIndex % 2 === 0}
          // eslint-disable-next-line react/no-array-index-key
          key={mobilePlanIndex}
        />
      ))}
    </MobilePlansWrapper>
  );
};

MobilePlanListBlock.propTypes = {
  value: PropTypes.shape({
    mobilePlans: PropTypes.arrayOf(PropTypes.shape({})),
    heading: PropTypes.string,
  }).isRequired,
};

export default MobilePlanListBlock;
