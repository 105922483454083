import styled, { css } from 'styled-components';

import { getThemeColor } from '@utils/styled';
import { media } from '@utils/mediaQueries';

import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const ItemTitle = styled(Typography)`
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;

  ${media.from560up(css`
    color: ${getThemeColor('grey.0')};
    font-size: 18px;
    font-weight: 400;
    line-height: 2;
  `)}
`;

export const ItemPrice = styled(Typography).attrs({
  variant: 'body1',
})`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;

  ${media.from560up(css`
    justify-content: center;
  `)}
`;

export const Container = styled(CMSBlockContainer)`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};

  p {
    margin-bottom: 0;
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  margin-bottom: 2rem;
`;

export const Group = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 0)};
`;

export const GroupsContainer = styled.div``;

export const GroupTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  margin-bottom: 2rem;
`;

export const GroupDescription = styled(Typography).attrs({
  customAs: 'div',
  variant: 'body2',
})`
  margin-top: 0.9rem;
  margin-bottom: 0;
  color: ${getThemeColor('bluegrey.60')};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${getThemeColor('bluegrey.90')};
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 7)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'equal', 7)};

  &:first-of-type {
    border-top: 1px solid ${getThemeColor('bluegrey.90')};
  }

  ${media.from560up(css`
    flex-direction: row;
  `)}
`;

export const ItemsContainer = styled.div``;
