import PropTypes from 'prop-types';
import React from 'react';

import { formatPhoneNumber } from '@utils/formatNumber';

import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

import { Subtitle, Title } from './ContactInformation.styled';

const ContactInformation = ({ value }) => {
  const { title, contact } = value;

  return (
    <CMSBlockContainer>
      <Title>{title}</Title>
      <Subtitle>{contact.title}</Subtitle>
      <p>
        {contact.name}
        <br />
        {contact.position}
      </p>
      <p>
        {contact.street}
        <br />
        {contact.zipCode} {contact.city}
      </p>
      <p>
        <a href={`mailto:${contact.email}`}>{contact.email}</a>
        <br />
        <a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a>
      </p>
    </CMSBlockContainer>
  );
};

ContactInformation.propTypes = {
  value: PropTypes.shape({
    contact: PropTypes.shape({
      city: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      position: PropTypes.string,
      street: PropTypes.string,
      title: PropTypes.string,
      zipCode: PropTypes.number,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactInformation;
