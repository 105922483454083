import React from 'react';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';

import { selectActiveVouchers, selectIsFetchingVouchers } from '@selectors/vouchers';
import { selectIsUserLogged } from '@selectors/auth';

import Bon from '@common/components/Voucher/Bon';
import { VIP_VOUCHERS } from '@common/routes';
import VouchersCarouselBase from '@common/components/VouchersCarouselBase';

import SeeMoreBons from './SeeMoreBons';

const BonsCarousel = () => {
  const { t } = useTranslation();
  const bons = useSelector(selectActiveVouchers);
  const isLoadingBons = useSelector(selectIsFetchingVouchers);
  const isUserLoggedIn = useSelector(selectIsUserLogged);

  if (!isUserLoggedIn || (!isLoadingBons && !bons.length)) {
    return null;
  }

  const items = bons.map(({ dateUsed, id, value, validUntil }) => (
    <Bon
      key={id}
      priceTag={value}
      onInvalidCardClick={() => {}}
      validUntil={validUntil && new Date(validUntil)}
      redeemed={dateUsed && new Date(dateUsed)}
    />
  ));

  return (
    <VouchersCarouselBase
      items={items}
      isLoading={isLoadingBons}
      seeMoreElement={<SeeMoreBons />}
      title={t('bonsCarousel.title', 'Deine CHF 10.– Bons')}
      linkHref={VIP_VOUCHERS}
      linkText={t('bonsCarousel.buttonText', 'Alle ansehen')}
    />
  );
};

export default BonsCarousel;
