/* stylelint-disable no-descending-specificity */
import styled from 'styled-components';

import { media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const Description = styled(Typography).attrs({
  variant: 'h6',
  customAs: 'div',
})`
  ${media.from640up`
    font-size: 2.8rem;
  `}
  ${media.from800up`
    font-size: 3rem;
  `}
  ${media.from1200up`
    font-size: 3.4rem;
  `}
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ shouldFlipImages }) =>
    shouldFlipImages ? '1fr 0.15fr 1.75fr' : '1.75fr 0.15fr 1fr'};
  ${({ theme }) => theme.getFluidAttribute('grid-row-gap', 2.1, 7.6)};
  grid-template-areas: ${({ shouldFlipImages }) =>
    shouldFlipImages ?
      `"first-image first-image first-image"
        "second-image second-image text"
        ". third-image third-image"` :
      `"first-image first-image first-image"
        "text second-image second-image"
        "third-image third-image ."`};
  background-color: #fff;
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'gallery', 1)};
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'gallery', 0)};

  ${media.from640up`
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 7, true)};
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 7, true)};
  `}

  ${media.from800up`
    // (100% - 12/10) / 2 * 100%
    // ((Whole element) - (ratio of all columns to content columns)) / (number of empty spaces (right and left)) * 100%
    margin-left: -10.5%;
    margin-right: -10.5%;
    grid-template-columns: ${({ shouldFlipImages }) =>
      shouldFlipImages ? '0.5fr 0.25fr 1fr' : '1fr 0.25fr 0.5fr'};
    grid-template-areas: ${({ shouldFlipImages }) =>
      shouldFlipImages ?
        `"second-image . first-image"
        "second-image . text"
        "second-image . third-image"` :
        `"first-image . second-image"
        "text . second-image"
        "third-image . second-image"`};
  `}

  ${media.from1200up`
    // (100% - 12/8) / 2 * 100%
    // ((Whole element) - (ratio of all columns to content columns)) / (number of empty spaces (right and left)) * 100%
    margin-left: -26.25%; // postiioning the container to the edge of the grid
    margin-right: -26.25%; // postiioning the container to the edge of the grid
  `}

  div:not(.blockText):nth-child(1) {
    grid-area: first-image;
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    ${({ theme }) => theme.getFluidAttribute('margin-right', 2.5, 8)};
    ${({ theme }) => theme.getFluidAttribute('margin-left', 2.5, 8)};

    ${media.from640up`
      margin-left: 0;
      margin-right: 0;
    `}

    ${media.from800up`
      max-width: 912px;
      grid-row: 1 / span 1;
      grid-column: ${({ shouldFlipImages }) => (shouldFlipImages ? '3 / -1' : '1 / span 1')};
    `}
  }

  div:nth-child(2) {
    grid-area: second-image;
    grid-row: 2 / 3;
    grid-column: ${({ shouldFlipImages }) => (shouldFlipImages ? '1 / 3' : '2 / -1')};
    ${({ theme, shouldFlipImages }) =>
      !shouldFlipImages && theme.getFluidAttribute('margin-right', 2.5, 8)};
    ${({ theme, shouldFlipImages }) =>
      shouldFlipImages && theme.getFluidAttribute('margin-left', 2.5, 8)};

    ${media.from640up`
      margin-left: 0;
      margin-right: 0;
    `};

    ${media.from800up`
      max-width: 493px;
      align-self: center;
      justify-self: ${({ shouldFlipImages }) => !shouldFlipImages && 'end'};
      grid-row: 1 / 4;
      grid-column: ${({ shouldFlipImages }) => (shouldFlipImages ? '1 / 2' : '3 / 4')};
    `}
  }

  div:nth-child(3) {
    grid-area: third-image;
    grid-row: 3 / -1;
    grid-column: ${({ shouldFlipImages }) => (shouldFlipImages ? '2 / -1' : '1 / 3')};
    ${({ theme, shouldFlipImages }) =>
      shouldFlipImages && theme.getFluidAttribute('margin-right', 2.5, 8)};
    ${({ theme, shouldFlipImages }) =>
      !shouldFlipImages && theme.getFluidAttribute('margin-left', 2.5, 8)};

    ${media.from640up`
      margin-left: 0;
      margin-right: 0;
    `}

    ${media.from800up`
      max-width: 632px;
      grid-column: ${({ shouldFlipImages }) => (shouldFlipImages ? '3 / 4' : '1 / 2')};
      margin: 0 5.5rem;
      justify-self: center;
    `}
  }

  ${Description} {
    align-self: center;
    grid-area: text;
    grid-row: 2 / 3;
    margin-right: 2rem;
    margin-left: ${({ shouldFlipImages }) => (shouldFlipImages ? '2rem' : '2.4rem')};

    ${media.from800up`
      margin-right: ${({ shouldFlipImages }) => (shouldFlipImages ? '30%' : '0')};
      margin-left: ${({ shouldFlipImages }) => (shouldFlipImages ? '0' : '30%')};
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    `}
  }
`;
