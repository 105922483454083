import PropTypes from 'prop-types';
import React from 'react';

import { isProduction } from '@utils/environmentCheck';

import { cmsComponentsData } from '@common/types/cms';
import {
  AccordionBlock,
  AreaOfOperationList,
  ContactInformation,
  FavoritesBlock,
  GalleryBlock,
  GiftTeaser,
  ImageBlock,
  ImageTextAlternateList,
  LookbookProductsGrid,
  LookbookProductsListSlider,
  LookbookTeaser,
  NewsTeaser,
  OfferList,
  PageCallToAction,
  PageTeaser,
  Paragraph,
  PressArticlesList,
  PressStatementsList,
  ProductCategoriesSlider,
  ProductsSlider,
  PromotionTeaser,
  StoreFinderBlock,
  StoreInformationBlock,
  TabularList,
  ProductsCarousel,
  MobilePlanBlock,
  MobilePlanListBlock,
  IconFactGridBlock,
  MobileFaqBlock,
  GalleryBlockWithText,
  PageOrLinkTeaser,
  FlowboxWidgetBlock,
  ButtonBlock,
  ProductGridBlock,
  BonsCarousel,
  CouponsCarousel,
  CookieBotBlock,
  CookieBotDeclarationBlock,
  TiktokEmbedCarousel,
  FactsGridBlock,
  YoutubeVideo,
  PersonListGridBlock,
  OptionCardBlock,
} from '@common/components/CMSComponents';
import {
  ACCORDION_BLOCK_TYPE,
  AREA_OF_OPERATION_LIST_BLOCK,
  CONTACT_INFORMATION_BLOCK_TYPE,
  FAVORITES_BLOCK_TYPE,
  GALLERY_BLOCK_TYPE,
  GIFT_TEASER_BLOCK_TYPE,
  IMAGE_BLOCK_TYPE,
  IMAGE_TEXT_ALTERNATE_LIST_BLOCK_TYPE,
  LOOKBOOK_PRODUCTS_GRID_BLOCK_TYPE,
  LOOKBOOK_PRODUCTS_SLIDER_CONTAINER_BLOCK_TYPE,
  LOOKBOOK_TEASER_BLOCK_TYPE,
  NEWS_TEASER_BLOCK_TYPE,
  OFFER_LIST_BLOCK_TYPE,
  PAGE_CALL_TO_ACTION_BLOCK_TYPE,
  PAGE_TEASER_BLOCK_TYPE,
  PARAGRAPH_BLOCK_TYPE,
  PRESS_ARTICLES_LIST_BLOCK_TYPE,
  PRESS_STATEMENTS_LIST_BLOCK_TYPE,
  PRODUCT_CATEGORIES_SLIDER_BLOCK_TYPE,
  PRODUCTS_SLIDER_BLOCK_TYPE,
  PROMOTION_TEASER_BLOCK_TYPE,
  STORE_FINDER_BLOCK_TYPE,
  STORE_INFORMATION_BLOCK_TYPE,
  TABULAR_LIST_BLOCK_TYPE,
  PRODUCTS_OFFER_CAROUSEL,
  PRODUCTS_NEW_ARRIVALS_CAROUSEL,
  PRODUCTS_BY_CATEGORIES_CAROUSEL,
  MOBILE_PLAN_BLOCK,
  MOBILE_FAQ_BLOCK,
  MOBILE_FACT_GRID_BLOCK,
  MOBILE_PLAN_LIST_BLOCK,
  GALLERY_BLOCK_WITH_TEXT,
  PAGE_OR_LINK_TEASER_BLOCK_TYPE,
  FLOWBOX_WIDGET_BLOCK,
  BUTTON_BLOCK_TYPE,
  PRODUCT_GRID_BLOCK_TYPE,
  BONS_CAROUSEL,
  COUPONS_CAROUSEL,
  CMS_COMPONENTS_NOT_REVERSING_LAYOUT,
  COOKIE_BOT_BANNER_BLOCK,
  COOKIE_BOT_DECLARATION_BLOCK,
  TIKTOK_EMBED_LIST_BLOCK,
  PERSON_LIST_LOCK_BLOCK,
  FACTS_GRID_BLOCK,
  YOUTUBE_EMBED_VIDEOS,
  TEXT_INFO_LIST_BLOCK,
  STORE_NEWS_BLOCK_TYPE,
} from '@common/constants/cms';

import ErrorBlock from './ErrorBlock';

const CMS_COMPONENTS_MAP = {
  [ACCORDION_BLOCK_TYPE]: AccordionBlock,
  [AREA_OF_OPERATION_LIST_BLOCK]: AreaOfOperationList,
  [CONTACT_INFORMATION_BLOCK_TYPE]: ContactInformation,
  [FAVORITES_BLOCK_TYPE]: FavoritesBlock,
  [GALLERY_BLOCK_TYPE]: GalleryBlock,
  [GIFT_TEASER_BLOCK_TYPE]: GiftTeaser,
  [IMAGE_BLOCK_TYPE]: ImageBlock,
  [IMAGE_TEXT_ALTERNATE_LIST_BLOCK_TYPE]: ImageTextAlternateList,
  [LOOKBOOK_PRODUCTS_GRID_BLOCK_TYPE]: LookbookProductsGrid,
  [LOOKBOOK_PRODUCTS_SLIDER_CONTAINER_BLOCK_TYPE]: LookbookProductsListSlider,
  [LOOKBOOK_TEASER_BLOCK_TYPE]: LookbookTeaser,
  [NEWS_TEASER_BLOCK_TYPE]: NewsTeaser,
  [STORE_NEWS_BLOCK_TYPE]: cmsValue =>
    cmsValue.data.map((storeNewsValue, index) => {
      const { reverseLayout: initialReverseLayout, ...componentData } = cmsValue;
      const isEven = index % 2 === 0;
      const reverseLayout = isEven ? initialReverseLayout : !initialReverseLayout;

      return (
        <NewsTeaser
          isStoreNews
          {...componentData}
          reverseLayout={reverseLayout}
          value={{ page: storeNewsValue }}
          index={index}
          key={storeNewsValue.id}
        />
      );
    }),
  [OFFER_LIST_BLOCK_TYPE]: OfferList,
  [PAGE_CALL_TO_ACTION_BLOCK_TYPE]: PageCallToAction,
  [PAGE_TEASER_BLOCK_TYPE]: PageTeaser,
  [PARAGRAPH_BLOCK_TYPE]: Paragraph,
  [PRESS_ARTICLES_LIST_BLOCK_TYPE]: PressArticlesList,
  [PRESS_STATEMENTS_LIST_BLOCK_TYPE]: PressStatementsList,
  [PRODUCT_CATEGORIES_SLIDER_BLOCK_TYPE]: ProductCategoriesSlider,
  [PRODUCTS_SLIDER_BLOCK_TYPE]: ProductsSlider,
  [PROMOTION_TEASER_BLOCK_TYPE]: PromotionTeaser,
  [STORE_FINDER_BLOCK_TYPE]: StoreFinderBlock,
  [STORE_INFORMATION_BLOCK_TYPE]: StoreInformationBlock,
  [TABULAR_LIST_BLOCK_TYPE]: TabularList,
  [PRODUCTS_OFFER_CAROUSEL]: ProductsCarousel,
  [PRODUCTS_NEW_ARRIVALS_CAROUSEL]: ProductsCarousel,
  [PRODUCTS_BY_CATEGORIES_CAROUSEL]: ProductsCarousel,
  [MOBILE_PLAN_BLOCK]: MobilePlanBlock,
  [MOBILE_FAQ_BLOCK]: MobileFaqBlock,
  [MOBILE_FACT_GRID_BLOCK]: IconFactGridBlock,
  [MOBILE_PLAN_LIST_BLOCK]: MobilePlanListBlock,
  [GALLERY_BLOCK_WITH_TEXT]: GalleryBlockWithText,
  [PAGE_OR_LINK_TEASER_BLOCK_TYPE]: PageOrLinkTeaser,
  [FLOWBOX_WIDGET_BLOCK]: FlowboxWidgetBlock,
  [BUTTON_BLOCK_TYPE]: ButtonBlock,
  [PRODUCT_GRID_BLOCK_TYPE]: ProductGridBlock,
  [BONS_CAROUSEL]: BonsCarousel,
  [COUPONS_CAROUSEL]: CouponsCarousel,
  [COOKIE_BOT_BANNER_BLOCK]: CookieBotBlock,
  [COOKIE_BOT_DECLARATION_BLOCK]: CookieBotDeclarationBlock,
  [TIKTOK_EMBED_LIST_BLOCK]: TiktokEmbedCarousel,
  [PERSON_LIST_LOCK_BLOCK]: PersonListGridBlock,
  [FACTS_GRID_BLOCK]: FactsGridBlock,
  [YOUTUBE_EMBED_VIDEOS]: YoutubeVideo,
  [TEXT_INFO_LIST_BLOCK]: OptionCardBlock,
};

const renderItem = ({ type, id, ...blockValue }, index, reverseLayout, firstAsHero, data) => {
  const CMSBlock = CMS_COMPONENTS_MAP[type];

  if (!CMSBlock || data?.isError) {
    if (!isProduction) {
      return (
        <ErrorBlock
          key={id}
          id={id}
          type={type}
          index={index}
          reason={data?.isError && 'Data fetching error'}
          blockValue={blockValue}
          isError={!!data?.isError}
        />
      );
    }

    return null;
  }

  return (
    <CMSBlock
      key={id}
      id={id}
      type={type}
      index={index}
      firstAsHero={firstAsHero}
      data={data?.results}
      reverseLayout={reverseLayout}
      {...blockValue}
    />
  );
};

const EMPTY_TYPES_TO_EXCLUDE = [STORE_NEWS_BLOCK_TYPE];

const filterOutEmptyTypes = (body, componentsData) =>
  body?.filter(
    item =>
      !EMPTY_TYPES_TO_EXCLUDE.includes(item.type) ||
      componentsData?.find(data => data.componentId === item.id && data.results?.length > 0)
  );

const CMSComponentsRenderer = ({ body, firstAsHero, componentsData }) => {
  let reverseLayout = true;

  return (
    <React.Fragment>
      {filterOutEmptyTypes(body, componentsData)?.map((item, index) => {
        if (!CMS_COMPONENTS_NOT_REVERSING_LAYOUT.includes(item.type)) {
          reverseLayout = !reverseLayout;
        }

        return renderItem(
          item,
          index,
          reverseLayout,
          firstAsHero,
          componentsData?.find(data => data.componentId === item.id)
        );
      })}
    </React.Fragment>
  );
};

CMSComponentsRenderer.propTypes = {
  body: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    })
  ),
  firstAsHero: PropTypes.bool,
  componentsData: cmsComponentsData,
};

CMSComponentsRenderer.defaultProps = {
  body: [],
  firstAsHero: false,
  componentsData: [],
};

export default CMSComponentsRenderer;
